import React, { useState } from "react";
import Seo from "../../components/Seo";
import Audio from "../../components/audioplayer/Audio";

import episode3 from "../../assets/radio/Episode3.mp3";
import Layout from "../../components/Layout";

import RadioPieceList from "../../components/radioPieceList";
import RadioNavV2 from "../../components/RadioNavV2";

const Station3 = () => {
  const [curTime, setCurTime] = useState(0);
  const [clickedTime, setClickedTime] = useState(0);
  const [clicked, setClicked] = useState(false);

  function setCurrentTime(time) {
    setCurTime(time);
  }

  return (
    <Layout>
      <div className="radioMain allTop">
        <RadioNavV2 />

        <Seo
          title={`Kingston School of Art Foundation 2021 • Station 3 • Radio`}
        />

        <div className="radioPage">
          <div className="radioInner">
            {/* <h1 className="radioTitle">Title of station 3</h1> */}

            <Audio
              src={episode3}
              time={clickedTime}
              clicked={clicked}
              setCurTime={(time) => setCurrentTime(time)}
            />
            <RadioPieceList
              setClickedTime={(time) => setClickedTime(time)}
              setClicked={(clicked) => setClicked(clicked)}
              clicked={clicked}
              curTime={curTime}
              pieceList={[
                {
                  start: 64,
                  end: 431,
                  timestamp: "01:04",
                  title: "The impact of Brexit on the creative industries",
                  students: ["Paola Gagliardi", "Pauline Ettlinger"],
                },
                {
                  start: 431,
                  end: 544,
                  timestamp: "07:11",
                  title: "Mars: Part 3",
                  students: ["Aparna Kmitra", "Phoebe Russell", "Sophie Lin"],
                },
                {
                  start: 565,
                  end: 931,
                  timestamp: "09:24",
                  title: "Home For Us",
                  students: ["Elizabeth Fraser"],
                },
                {
                  start: 931,
                  end: 1065,
                  timestamp: "15:31",
                  title: "Sounds of Home: Part 1",
                  students: ["Elena Hornby"],
                },
                {
                  start: 1065,
                  end: 1653,
                  timestamp: "17:45",
                  title: "Everything we wear is amazing",
                  students: ["Sophia Laycock", "Eve King "],
                },
                {
                  start: 1665,
                  end: 1724,
                  timestamp: "27:45",
                  title: "Advert",
                  students: ["Glump Collective"],
                },
                {
                  start: 1733,
                  end: 1911,
                  timestamp: "28:53",
                  title: "The seed of superstition",
                  students: ["Amelie Everett"],
                },
                {
                  start: 1928,
                  end: 2542,
                  timestamp: "32:08",
                  title: "Off The Fence",
                  students: ["Georgia Denny", "Oliver Diaz"],
                },
                {
                  start: 2555,
                  end: 2697,
                  timestamp: "42:35",
                  title: "Sounds of Home: Part 2",
                  students: ["Ellie Ashby"],
                },
                {
                  start: 2716,
                  end: 2898,
                  timestamp: "45:16",
                  title: "Looking forward to",
                  students: ["Duncan Mcallister", "Lily Flynn", "Ailsa Davies"],
                },
              ]}
            />
            <p className="radioCredits">
              Presenter: Joshua Cornwell / Sound Design and Editing: Alex Abrams
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Station3;
